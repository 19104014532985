import React, { useState, useContext, useEffect } from 'react';
import { Route, useParams, Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Typography, Divider, MenuList, MenuItem, Button } from '@material-ui/core';
import Topbar from './components/Topbar/Topbar';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import PersonOutlineIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import FolderOutlinedIcon from '@material-ui/icons/Folder';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import GearOutlinedIcon from '@material-ui/icons/Settings';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TextsmsIcon from '@material-ui/icons/Textsms';
import ChatIcon from '@material-ui/icons/Chat';
import InfoIcon from '@material-ui/icons/Info';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShopIcon from '@material-ui/icons/Shop';
import BallotIcon from '@material-ui/icons/Ballot';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    maxHeight: 'calc(100% - 64px)',
    flexGrow: 1,
    maxWidth: '100%',
  },
  main: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'auto',
  },
  main__container: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  drawer: {
    width: 240,
    flexShrink: 0,
    padding: '0',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'width 0.2s',
  },
  drawer_reduced: {
    width: 66,
  },
  drawerPaper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
  },
  drawer__header: {
    color: '#90A4AE',
    fontSize: 11,
    padding: '21px 16px 11px 16px',
  },
  menuItem: {
    color: '#37474F',
    fontSize: 14,
    padding: '13px 16px',
  },
  menuItem__icon: {
    color: '#546E7A',
    marginRight: 10,
  },
  backToMeetups: {
    display: 'flex',
    alignItems: 'center',
    padding: '21px 16px',
  },
  backToMeetups__label: {
    color: '#607D8B',
    fontSize: 11,
    whiteSpace: 'nowrap',
  },
  backToMeetups__arrow: {
    color: '#546E7A',
    marginRight: 10,
    pointerEvents: 'none',
    transition: 'transform 0.2s',
  },
  backToMeetups__arrow_rotated: {
    transform: 'rotate(180deg)',
  },
  divider: {
    margin: '0 16px',
  },
  spacer: {
    flexGrow: 1,
  },
  menuReducer: {
    display: 'flex',
    padding: '26px 16px',
  },
  hider: {
    display: 'none',
  },
  centerer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Main = props => {
  const { children } = props;
  const classes = useStyles();
  const { mid: meetupId } = useParams();
  const appContext = useContext(AppContext);
  const [menuReduced, setMenuReduced] = useState(localStorage.getItem('menuReduced') == 'true' ? true : false);
  const [openMenu, setOpenMenu] = useState({});

  useEffect(() => {
    const storedMenuState = localStorage.getItem('openMenuState');
    if (storedMenuState) {
      setOpenMenu(JSON.parse(storedMenuState));
    } else {
      const initialOpenState = menuItems.reduce((acc, item) => {
        if (item.innerItems) acc[item.title] = true; 
        return acc;
      }, {});
      setOpenMenu(initialOpenState);
    }
  }, []);

  const toggleSubMenu = (title) => {
    setOpenMenu(prev => {
      const newState = {
        ...prev,
        [title]: !prev[title]
      };
      localStorage.setItem('openMenuState', JSON.stringify(newState));
      return newState;
    });
  };

  const menuItems = [
    {
      title: 'Посетители',
      icon: <PeopleAltIcon className={classes.menuItem__icon} style={{width: '20px', height: '20px'}}/>,
      link: `/m/${meetupId}/visitor`,
      select: ["/group", "/settings", "/visitor"]
    },
    {
      title: 'Регистрация',
      icon: <CheckBoxOutlinedIcon className={classes.menuItem__icon} style={{width: '20px', height: '20px'}}/>,
      link: `/m/${meetupId}/online-registration`,
      innerItems: [{
        title: 'Онлайн',
        link: `/m/${meetupId}/online-registration`,
        select: ["/online-registration"]
      },
      {
        title: 'Офлайн',
        link: `/m/${meetupId}/desk`,
        select: ["/desk"]
      },
      {
        title: 'Редактор бейджей',
        link: `/m/${meetupId}/badge`,
        select: ["/badge"]
      },
      {
        title: 'Статистика',
        link: `/m/${meetupId}/stat/common`,
        select: ["/stat/common", "/stat/of-desks"]
      },]
    },
    {
      title: 'Чат-бот Telegram',
      icon: <ChatBubbleOutlineOutlinedIcon className={classes.menuItem__icon} style={{width: '20px', height: '20px'}}/>,
      link: `/m/${meetupId}/chat/channels`,
      innerItems: [{
        title: 'Настройки',
        link: `/m/${meetupId}/chat/channels`,
        select: ["/chat/channels"]
      },
      {
        title: 'О мероприятии',
        link: `/m/${meetupId}/info`,
        select: ["/info"]
      },
      {
        title: 'Программа',
        link: `/m/${meetupId}/program/hall`,
        select: ["/program"]
      },
      {
        title: 'Новости и опросы',
        link: `/m/${meetupId}/news`,
        select: ["/news"]
      },
      {
        title: 'Нетворкинг',
        link: `/m/${meetupId}/chat/networking`,
        select: ["/chat/networking"]
      },
      {
        title: 'Переписки',
        link: `/m/${meetupId}/chat/logs`,
        select: ["/chat/logs"]
      },]
    },
    {
      title: 'Рассылки',
      icon: <MailOutlineIcon className={classes.menuItem__icon} style={{width: '20px', height: '20px'}}/>,
      link: `/m/${meetupId}/mailing`
    }
  ];

  const regexp = /\/m\/[\d-]+(\/[\w-]+).*/
  const path = window.location.pathname.startsWith("/m/") ? regexp.exec(window.location.pathname)[1] : ""
  const fullPathInfo = window.location.pathname.startsWith("/m/") ? regexp.exec(window.location.pathname)[0] : ""
  const checkSelected = (item) => {
    if (item.select && item.select[0].split('/').length === 3){
      const cc = fullPathInfo.split('/')
      const fullPath = '/' + cc.at(-2) + '/' + cc.at(-1)
      return item.select && item.select.indexOf(fullPath) !== -1
    }
    return item.select && item.select.indexOf(path) !== -1
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar />
      <div className={classes.content}>
        <Route path='/m/:mid'>
          <Drawer
            anchor='left'
            variant='permanent'
            className={clsx({
              [classes.drawer]: true,
              [classes.drawer_reduced]: menuReduced,
            })}
            classes={{
              paper: classes.drawerPaper,
            }}
            tabIndex={-1}
          >
            <Button
              className={clsx({
                [classes.backToMeetups]: true,
              })}
              underline='none'
              href={window.location.origin + '/meetup'}
            >
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <KeyboardBackspaceIcon className={classes.backToMeetups__arrow} style={{ width: '20px', height: '20px' }} />
                <Typography
                  className={clsx({
                    [classes.backToMeetups__label]: true,
                    [classes.hider]: menuReduced,
                  })}
                >
                  СПИСОК МЕРОПРИЯТИЙ
                </Typography>
              </div>
            </Button>
            <Divider className={classes.divider} />
            <MenuList>
              {menuItems.map((item) => (
                <div key={item.title}>
                  <MenuItem
                    component={Link}
                    to={item.link}
                    selected={checkSelected(item)}
                  >
                    {item.icon}
                    <Typography>{item.title}</Typography>
                    {item.innerItems && (
                      <div style={{
                        opacity: 0.5,
                        marginLeft: 'auto',
                        display: 'flex', 
                        alignItems: 'center'
                      }}
                      onClick={(e) => {
                        e.preventDefault(); 
                        toggleSubMenu(item.title);
                      }}
                      >
                        {openMenu[item.title] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </div>
                    )}
                  </MenuItem>
                  {item.innerItems && openMenu[item.title] && (
                    <MenuList style={{padding: 0}}>
                      {item.innerItems.map((innerItem, index) => (
                        <MenuItem key={index} component={Link} to={innerItem.link} selected={checkSelected(innerItem)}>
                          <Typography style={{ paddingLeft: '30px' }}>{innerItem.title}</Typography>
                        </MenuItem>
                      ))}
                    </MenuList>
                  )}
                </div>
              ))}
            </MenuList>
            <Divider className={classes.divider} />
            <MenuItem
              key={"docs"}
              component={Link}
              selected={false}
              className={classes.menuItem}
              tabIndex={menuItems.length+1}
              to={"//docs.eventos42.ru/"}
              target={'_blank'}
            >
              <HelpOutlineIcon className={classes.menuItem__icon}/>
              <Typography className={clsx({[classes.hider]: menuReduced})}>
                Документация
              </Typography>
            </MenuItem>

            <div className={classes.spacer}></div>

            <Button
              style={{
                background: 'inherit',
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                padding: 16,
                justifyContent: 'start',
              }}
              onClick={() => {
                localStorage.setItem('menuReduced', !menuReduced);
                setMenuReduced(!menuReduced);
              }}
            >
              <KeyboardBackspaceIcon
                className={clsx({
                  [classes.backToMeetups__arrow]: true,
                  [classes.backToMeetups__arrow_rotated]: menuReduced,
                })}
              />
              <Typography
                className={clsx({
                  [classes.backToMeetups__label]: true,
                  [classes.hider]: menuReduced,
                })}
              >
                СВЕРНУТЬ МЕНЮ
              </Typography>
            </Button>
          </Drawer>
        </Route>
        <main className={classes.main}>
          <div className={classes.main__container}>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
