import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const tagToRussian = {
  imported: 'Загружено из файла',
  manual: 'Добавлено вручную',
  onLine: 'Онлайн-регистрация',
  onSite: 'Саморегистрация',
  chat: 'Чат-боты',
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Roboto, sans-serif',
  },

  tableContainer: {
    marginBottom: theme.spacing(3),
    padding: '0 25px 25px 25px',
    flex: '1 0 40%',

    '&:nth-child(2n + 1)': {
      marginRight: theme.spacing(4),
    },
  },

  tableContainer__title: {
    height: 45,
    paddingLeft: 25,
    lineHeight: '45px',
    fontSize: 20,
    fontWeight: 'normal',
    color: '#1665D8',
  },

  table: {
    tableLayout: 'fixed',
    border: '1px solid #E0E0E0',
    '& th, td': {
      textAlign: 'center',
      borderBottom: '1px solid #E0E0E0',
    },
  },

  table__head: {
    backgroundColor: 'white',

    '& th': {
      fontWeight: 'normal',
      color: 'rgba(0, 0, 0, 0.56)',
    },
  },

  table__sum: {
    fontWeight: 'bold',
  },
}));

function dateToDotFormat(date) {
  date = new Date(date);

  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
}

const Common = props => {
  const { data } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.tableContainer}>
        <Typography className={classes.tableContainer__title} variant='h1'>
          Посетители
        </Typography>
        <Table className={classes.table}>
          <TableHead className={classes.table__head}>
            <TableRow>
              <TableCell> Пришло: </TableCell>
              <TableCell> Из базы </TableCell>
              <TableCell> Онлайн </TableCell>
              <TableCell> Новых </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.countBySource.map((row, index) => {
              let isSum = !Boolean(row.tag);
              return (
                <TableRow key={index}>
                  <TableCell className={isSum && classes.table__sum}>
                    {' '}
                    {isSum ? 'Всего:' : dateToDotFormat(row.tag)}{' '}
                  </TableCell>
                  <TableCell className={isSum && classes.table__sum}> {row.fromBase} </TableCell>
                  <TableCell className={isSum && classes.table__sum}> {row.online} </TableCell>
                  <TableCell className={isSum && classes.table__sum}> {row.new} </TableCell>
                  <TableCell className={classes.table__sum}> {row.sum} </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
      <Card className={classes.tableContainer}>
        <Typography className={classes.tableContainer__title} variant='h1'>
          Бейджи
        </Typography>
        <Table className={classes.table}>
          <TableHead className={classes.table__head}>
            <TableRow>
              <TableCell> Напечатано: </TableCell>
              <TableCell> На мероприятии </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.countByBadge.map((row, index) => {
              let isSum = !Boolean(row.tag);
              return (
                <TableRow key={index}>
                  <TableCell className={isSum && classes.table__sum}>
                    {' '}
                    {isSum ? 'Всего:' : dateToDotFormat(row.tag)}{' '}
                  </TableCell>
                  <TableCell className={isSum && classes.table__sum}> {row.onMeetup} </TableCell>
                  <TableCell className={classes.table__sum}> {row.sum} </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
      <Card className={classes.tableContainer}>
        <Typography className={classes.tableContainer__title} variant='h1'>
          Источник регистрации
        </Typography>
        <Table className={classes.table}>
          <TableHead className={classes.table__head}>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell> В базе </TableCell>
              <TableCell> Пришло </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.countByRegistration.map((row, index) => {
              let isSum = !Boolean(row.tag);
              return (
                <TableRow key={index}>
                  <TableCell className={isSum && classes.table__sum}>
                    {' '}
                    {isSum ? 'Всего:' : tagToRussian[row.tag]}{' '}
                  </TableCell>
                  <TableCell className={isSum && classes.table__sum}> {row.inBase} </TableCell>
                  <TableCell className={isSum && classes.table__sum}> {row.arrived} </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
      <Card className={classes.tableContainer}>
        <Typography className={classes.tableContainer__title} variant='h1'>
          Группы
        </Typography>
        <Table className={classes.table}>
          <TableHead className={classes.table__head}>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell> Количество посетителей</TableCell>
              <TableCell> Из них пришло </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.countByGroup.map((row, index) => {
              let isSum = !Boolean(row.tag);
              return (
                <TableRow key={index}>
                  <TableCell className={isSum && classes.table__sum}> {isSum ? 'Всего:' : row.tag} </TableCell>
                  <TableCell className={isSum && classes.table__sum}> {row.visitors} </TableCell>
                  <TableCell className={isSum && classes.table__sum}> {row.arrivedVisitors} </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
      <Card className={classes.tableContainer}>
  <Typography className={classes.tableContainer__title} variant="h1">
    Регистрация на мероприятии
  </Typography>
  <Table className={classes.table}>
    <TableHead className={classes.table__head}>
      <TableRow>
        <TableCell> Дата </TableCell>
        <TableCell> Зарегистрировано </TableCell>
        <TableCell> Пришло из базы </TableCell>
        <TableCell> Добавлено на стойке </TableCell>
        <TableCell> Напечатано бейджей </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data.countBySource.map((row, index) => {
        const badgeRow = data.countByBadge[index] || {};
        const isSum = !Boolean(row.tag);

        return (
          <TableRow key={index}>
            <TableCell className={isSum && classes.table__sum}>
              {isSum ? 'Всего:' : dateToDotFormat(row.tag)}
            </TableCell>
            <TableCell className={isSum && classes.table__sum}> </TableCell>
            <TableCell className={isSum && classes.table__sum}>
              {row.fromBase}
            </TableCell>
            <TableCell className={isSum && classes.table__sum}>
              {row.new}
            </TableCell>
            <TableCell className={isSum && classes.table__sum}>
              {badgeRow.onMeetup || ''}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
</Card>
    </div>
  );
};

export default Common;
